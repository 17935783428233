<template>
  <div class="calendar">
    <div class="empty" @click="onClose"></div>
    <div class="body">
      <div class="header-tool">
        <div class="year-month">{{ yearMonth }}</div>
        <div class="tool">
          <img :src="leftIcon" style="margin-right:18px" @click="prevMonth()" />
          <img :src="rightIcon" @click="nextMonth()" />
        </div>
      </div>
      <div class="main">
        <div class="header">
          <div class="item-1" v-for="(item, index) in dayOfWeek" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="day">
          <div
            class="item-2"
            v-for="(item, index) in dayList"
            :key="index"
            v-bind:class="{ active: item.isToday == true }"
            @click="onSelectDate(item)"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "calendar",
  props: {},
  data() {
    return {
      leftIcon: require("@/assets/images/user-learn-plan/arrow-left.png"),
      rightIcon: require("@/assets/images/user-learn-plan/arrow-right.png"),
      daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      //year: 0,
      //month: 0, // 月份是从 0 开始计算的，即 1 月是 0。
      // day: 0, //dayjs#date 是该月的日期。 dayjs#day 是星期几。从0(星期天)到6(星期六)
      firstDateOfMonth: 0, // 接受1到31的数字
      currentDay: null,
      today: null,
      dayOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
      dayList: [],
      yearMonth: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.currentDay = dayjs();
    this.today = dayjs();
    const month = this.currentDay.month();
    const year = this.currentDay.year();
    this.wrapDaysInMonth(year);
    // this.day = dayjs().day(); // 获取或设置星期几。
    // this.dayOfMonth = dayjs().date(); // 获取或设置月份里的日期。
    this.render(); // month 从0 开始
  },
  methods: {
    wrapDaysInMonth(year) {
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        this.daysInMonth[1] = 29;
      } else {
        this.daysInMonth[1] = 28;
      }
    },
    //本月的第一天
    getFirstDate(ts) {
      const day = dayjs(ts);
      const month = day.month();
      const year = day.year();
      let tmpDate = new Date(year, month, 1);
      let firstDateOfMonth = dayjs(tmpDate);
      return firstDateOfMonth;
    },
    //本月的最后一天
    getLastDate(ts) {
      const day = dayjs(ts);
      const month = day.month();
      const year = day.year();
      this.wrapDaysInMonth(year);
      let tmpDate = new Date(year, month, this.daysInMonth[month]);
      let lastDateOfMonth = dayjs(tmpDate);
      return lastDateOfMonth;
    },
    render() {
      const month = this.currentDay.month();
      const year = this.currentDay.year();
      this.yearMonth = this.currentDay.format("YYYY年M月");
      this.wrapDaysInMonth(year);

      const dayCount = this.daysInMonth[month];
      this.firstDateOfMonth = this.getFirstDate(this.currentDay.valueOf());
      let dayOfWeek = this.firstDateOfMonth.day();

      let dayList = [];
      for (let i = 0; i < dayOfWeek; i++) {
        dayList.push({
          dayjsObj: null,
          value: "",
          isToday: false,
        });
      }
      let dayjsItem = this.firstDateOfMonth;
      for (let i = 0; i < dayCount; i++) {
        dayList.push({
          dayjsObj: dayjsItem,
          value: dayjsItem.date(),
          isToday: false,
        });
        dayjsItem = dayjsItem.add(1, "day");
      }
      for (let i = dayCount; i < 35; i++) {
        dayList.push({
          dayjsObj: null,
          value: "",
          isToday: false,
        });
      }
      this.dayList.splice(0, 1);
      this.dayList = dayList;
      for (let day of this.dayList) {
        if (
          day != null &&
          day.dayjsObj != null &&
          day.dayjsObj.year() == this.today.year() &&
          day.dayjsObj.month() == this.today.month() &&
          day.dayjsObj.date() == this.today.date()
        ) {
          day.isToday = true;
        }
      }
    },
    prevMonth() {
      let firstDate = this.getFirstDate(this.currentDay.valueOf());
      let prevMonthDate = firstDate.subtract(1, "day");
      this.currentDay = prevMonthDate;
      this.render();
    },
    nextMonth() {
      let nextDate = this.getLastDate(this.currentDay.valueOf());
      let nextMonthDate = nextDate.add(1, "day");
      this.currentDay = nextMonthDate;
      this.render();
    },
    onClose() {
      this.$EventBus.$emit("onCloseFullCalendar", true);
    },
    onSelectDate(item) {
      this.$EventBus.$emit("onDataFullCalendar", item);
      this.$EventBus.$emit("onCloseFullCalendar", true);
    },
  },
};
</script>

<style scoped lang="less">
.calendar {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  // justify-content: space-between;
  .empty {
    flex: 1;
  }
  .body {
    height: 360px;
    background: #ffffff;
    border-radius: 24px 24px 0px 0px;
    .header-tool {
      margin: 20px 54px;
      font-size: 18px;
      color: #242424;
      letter-spacing: 0;
      line-height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .tool {
        img {
          height: 24px;
          width: 24px;
        }
      }
    }
    .main {
      margin: 0px 54px;
      .header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 280px;
        .item-1 {
          width: 40px;
          height: 40px;
          //margin: 1px 6px;
          font-size: 16px;
          color: #9298a4;
          text-align: center;
          letter-spacing: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .day {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 280px;
        .item-2 {
          width: 40px;
          height: 40px;
          //margin: 1px 6px;
          font-size: 16px;
          color: #242424;
          letter-spacing: 0;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .active {
          background: #4d52d1;
          border-radius: 16px;
          color: #ffffff;
          font-weight: 500;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>
