<template>
  <div class="hour">
    <div class="empty" @click="onClose"></div>
    <div class="body">
      <div class="header-tool">
        <div class="label">时间</div>
      </div>
      <div class="main">
        <div class="time">
          <div class="item" v-for="(item, index) in evenHour" :key="index" @click="onSelectTime(item)">
            <img :src="todoIcon" />
            <div class="time-label">{{ item }}</div>
          </div>
        </div>
        <div class="time">
          <div class="item" v-for="(item, index) in oddHour" :key="index" @click="onSelectTime(item)">
            <img :src="todoIcon" />
            <div class="time-label">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "calendar",
  props: {},
  data() {
    return {
      leftIcon: require("@/assets/images/user-learn-plan/arrow-left.png"),
      rightIcon: require("@/assets/images/user-learn-plan/arrow-right.png"),
      todoIcon: require("@/assets/images/user-learn-plan/todo.png"),
      evenHour: [],
      oddHour: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.today = dayjs();
    this.initCalendar(this.year, this.month); // month 从0 开始
  },
  methods: {
    initCalendar() {
      this.evenHour = [
        // 偶数
        "00 : 00",
        "02 : 00",
        "04 : 00",
        "04 : 00",
        "06 : 00",
        "08 : 00",
        "10 : 00",
        "12 : 00",
        "14 : 00",
        "16 : 00",
        "18 : 00",
        "20 : 00",
        "22 : 00",
      ];
      this.oddHour = [
        // 奇数
        "01 : 00",
        "03 : 00",
        "05 : 00",
        "07 : 00",
        "09 : 00",
        "11 : 00",
        "13 : 00",
        "15 : 00",
        "17 : 00",
        "19 : 00",
        "21 : 00",
        "23 : 00",
      ];
    },
    nextMonth() {},
    onClose() {
      this.$EventBus.$emit("onCloseHourCalendar", true);
    },
    onSelectTime(item) {
      //alert(1);
      console.log("on select time", item);
      let strValue = item.replaceAll(" ", "");
      let field = item.split(":");
      let ret = {
        hour: parseInt(field[0]),
        minute: 0,
        strValue: strValue,
      };
      this.$EventBus.$emit("onDataHourCalendar", ret);
      this.$EventBus.$emit("onCloseHourCalendar", true);
    },
  },
};
</script>

<style scoped lang="less">
.hour {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  // justify-content: space-between;
  .empty {
    flex: 1;
  }
  .body {
    height: 330px;
    background: #ffffff;
    border-radius: 24px 24px 0px 0px;
    display: flex;
    flex-direction: column;

    .label {
      margin: 20px 54px;
      font-size: 18px;
      color: #242424;
      letter-spacing: 0;
      line-height: 30px;
      display: flex;
      flex-direction: row;
    }
    .main {
      margin: 0px 54px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
      overflow-y: scroll;
      .time {
        display: flex;
        flex-direction: column;
        .item {
          width: 140px;
          margin: 12px 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            height: 16px;
            width: 16px;
            margin: 0px 13px 0px 0px;
          }
          .time-label {
            font-size: 18px;
            color: #505051;
            letter-spacing: 0.4px;
          }
        }
      }
    }
  }
}
</style>
